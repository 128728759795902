export function scrollMenu() {
  const scrollMenuHolders = document.querySelectorAll("[data-scroll-menu-holder]");
  scrollMenuHolders.forEach((scrollMenuHolder) => {
    const scrollMenuActiveItem = scrollMenuHolder.querySelector("[class*='current-cat']");
    if (scrollMenuActiveItem) {
      const paddingLeft = parseInt(window.getComputedStyle(scrollMenuHolder).getPropertyValue("padding-left"));
      scrollMenuHolder.scrollTo({
        top: 0,
        left: scrollMenuActiveItem.offsetLeft - paddingLeft,
      });
    }
  });
}
