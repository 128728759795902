export function counter() {
  const els = document.querySelectorAll("[data-counter-target]");
  if (els.length) {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            function counter(el, duration) {
              const targetNumber = Number(el.dataset.counterTarget);
              const startNumber = el.textContent;
              const startTime = performance.now();

              function updateCounter(currentTime) {
                const elapsedTime = currentTime - startTime;
                const progress = Math.min(elapsedTime / duration, 1);
                const currentValue = progress * (targetNumber - startNumber);
                const value = Number.isInteger(targetNumber) ? Math.floor(currentValue) : currentValue.toFixed(1);

                el.textContent = value;

                if (progress < 1) {
                  requestAnimationFrame(updateCounter);
                } else {
                  el.textContent = targetNumber;
                }
              }

              requestAnimationFrame(updateCounter);
            }
            counter(entry.target, 1000);
            observer.unobserve(entry.target);
          }
        });
      },
      {
        rootMargin: "-30px",
      }
    );
    els.forEach((el) => {
      observer.observe(el);
    });
  }
}
