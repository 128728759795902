import "../scss/style.scss";

import { accordion } from "./modules/accordion";
import { tabs } from "./modules/tabs";
import { overlayMenu } from "./modules/overlay-menu";
import { videoAutoplay } from "./modules/video-autoplay";
import { pdfLinks } from "./modules/pdf-links";
import { counter } from "./modules/counter";
import { scrollMenu } from "./modules/scroll-menu";

pdfLinks();

videoAutoplay();

overlayMenu();

accordion();

tabs();

counter();

scrollMenu();
