export const overlayMenu = () => {
  const overlayMenuBreakpoint = parseInt(window.getComputedStyle(document.documentElement).getPropertyValue("--overlay-menu-breakpoint")) || false;
  const overlayMenu = document.querySelector("[data-overlay-menu]");
  const overlayMenuBody = document.querySelector("[data-overlay-menu-body]");
  const overlayMenuTogglers = document.querySelectorAll("[data-overlay-menu-toggler]");

  const siteMenu = document.querySelector(".site-menu");
  const submenuBackButtons = siteMenu.querySelectorAll("[data-sub-menu-back]");

  const activeClass = "active";
  const changingClass = "changing";

  const menuItemsHasChildrenSelector = ".menu-item-has-children";
  const submenuParentLinks = siteMenu.querySelectorAll(menuItemsHasChildrenSelector + " > a");

  const isTouchDevice = () => {
    return window.matchMedia("(pointer: coarse)").matches;
  };

  const isOverlayMenu = () => {
    return typeof overlayMenuBreakpoint === "number" ? window.innerWidth < overlayMenuBreakpoint : true;
  };

  const overlayMenuToggleActiveState = () => {
    // document.documentElement.classList.toggle("no-scroll");
    overlayMenu.classList.toggle(activeClass);
    overlayMenu.classList.add(changingClass);
    overlayMenu.ontransitionend = () => {
      overlayMenu.classList.remove(changingClass);
    };

    if (!overlayMenu.classList.contains(activeClass)) {
      submenusRemoveActiveState();
    }
  };

  const submenusRemoveActiveState = (e) => {
    const eventTarget = e?.target;
    const activeSubmenus = siteMenu.querySelectorAll("." + activeClass);
    activeSubmenus.forEach((activeSubmenu) => {
      if (!activeSubmenu.contains(eventTarget) || !eventTarget) {
        activeSubmenu.classList.remove(activeClass);
      }
    });
  };

  //Submenu Parent Link Click
  submenuParentLinks.forEach((submenuParentLink) => {
    submenuParentLink.onclick = (e) => {
      if (isOverlayMenu()) {
        const childSubmenu = submenuParentLink.parentElement.querySelector(".sub-menu");
        const currentSubmenu = submenuParentLink.closest(".sub-menu");
        const scrollTopElements = [overlayMenuBody, childSubmenu, currentSubmenu];
        scrollTopElements.forEach((scrollTopElement) => {
          scrollTopElement?.scrollTo(0, 0);
        });
      }
      if (isOverlayMenu() || isTouchDevice()) {
        e.preventDefault();
        submenuParentLink.closest(menuItemsHasChildrenSelector).classList.toggle(activeClass);
      }
    };
  });

  //Back Click
  submenuBackButtons.forEach((subMenuBackButton) => {
    subMenuBackButton.onclick = () => {
      subMenuBackButton.closest(menuItemsHasChildrenSelector).classList.remove(activeClass);
    };
  });

  //Toggler Click
  overlayMenuTogglers.forEach((toggler) => {
    toggler.onclick = () => {
      overlayMenuToggleActiveState();
    };
  });

  //Escape Click
  document.onkeydown = (e) => {
    if (e.key == "Escape" && overlayMenu.classList.contains(activeClass)) {
      overlayMenuToggleActiveState();
    }
  };

  //Outside Click
  document.onclick = (e) => {
    submenusRemoveActiveState(e);
  };
};
